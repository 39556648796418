// browser polyfill
if (!window.IntersectionObserver) {
    console.log('IntersectionObserver not support loading polyfill');
    require('intersection-observer');
}

if (!window.ResizeObserver) {
    console.log('ResizeObserver not support loading polyfill');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('resize-observer').install();
}
