import { createMatchSelector, push } from 'connected-react-router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { _get } from '../../../../utils/object-prop';
import { AppState } from '../../../reducers';
import { selectTranslator } from '../../languages/trans';
import { selectActiveProduct } from '../../products/reducers/products';
import actions from '../actions';
import { ACTION_TYPES, BOOKING_STEP1, BOOKING_STEP2 } from '../constants';
import { selectBookingMenus, selectBookingStepData } from '../reducers';
import { BookingStep1 } from '../types';
import { validateStep1, validateStep2 } from '../validations';
import step1 from './step1';
import step2 from './step2';
import step3 from './step3';

function* taskUpdateBookingUrlOnMoveToNextStep() {
  const stepIndex = yield select(
    (state: AppState) => state.booking.activeStepIndex,
  );
  const stepInfo = yield select((state: AppState) => state.booking.steps);
  const transFn = yield select(selectTranslator);
  const product = yield select(selectActiveProduct);
  const activeStepRoute = stepInfo[stepIndex].route;

  const matchSelector = createMatchSelector(transFn(`routes.book`));
  const match = yield select(matchSelector);

  let productTypeSlug = product.product_type;
  if (productTypeSlug === 'restaurant') {
    productTypeSlug = transFn('booking_restaurant_slug');
  }
  const route = transFn(`routes.book`, {
    step: activeStepRoute,
    productType: productTypeSlug,
    slug: product.slug,
  });

  if (match && route !== match.url) {
    yield put(actions.loading(true));
    yield put(push(route));
    yield put(actions.loading(false));
  }
}

/**
 * Update complete to false when step is complete then user have changes
 * @param action
 */
function* taskSetCompleteOnFill(action) {
  const { is_complete, errors } = yield select(
    selectBookingStepData,
    action.payload.step,
  );
  if (is_complete) {
    yield put(actions.completeStep(action.payload.step, false));
  }

  const newErrors = errors;
  Object.keys(action.payload.data).forEach(key => {
    if (_get(newErrors, key)) {
      delete newErrors[key];
    }
  });

  yield put(actions.setStepErrors(action.payload.step, newErrors));
}

export function* invalidTableSizeMenuValidate(stepData: BookingStep1) {
  let menus = yield select(selectBookingMenus);
  for (let menusId in stepData.data.menus) {
    let selectedMenu = stepData.data.menus[menusId];
    let selectedMenuData = menus.find(menu => menu.id === Number(menusId));

    let hasTableSize = () => {
      return (
        selectedMenuData?.table_sizes
          ?.split(',')
          ?.find(item => Number(item) === Number(selectedMenu.num_person)) !==
        undefined
      );
    };

    if (
      selectedMenuData?.limit_table_size === 1 &&
      selectedMenu.num_person > 0 &&
      !hasTableSize()
    ) {
      console.log(selectedMenuData.name);
      return selectedMenuData.name;
    }
  }

  return false;
}

export function* validateSteps(action: ReturnType<typeof actions.formSubmit>) {
  const stepData = yield select(selectBookingStepData, BOOKING_STEP2);
  const step1Data = yield select(selectBookingStepData, BOOKING_STEP1);

  let errors = {};
  const { step: currentStep } = action.payload;
  if (currentStep === BOOKING_STEP1) {
    errors = validateStep1(step1Data);
  } else if (currentStep === BOOKING_STEP2) {
    errors = yield call(validateStep2, stepData);
  }

  let invalidTableSizeMenu = yield call(
    invalidTableSizeMenuValidate,
    step1Data,
  );

  if (invalidTableSizeMenu) {
    errors['menu_table_size_limit'] = invalidTableSizeMenu;
  }

  const isComplete = Object.values(errors).length === 0;
  yield put(actions.setStepErrors(currentStep, errors));
  yield put(actions.completeStep(currentStep, isComplete));

  return {
    errors,
    isComplete,
  };
}

export default function* bookingWatchers() {
  yield all([
    takeEvery(
      actions.moveToNextStep.type,
      taskUpdateBookingUrlOnMoveToNextStep,
    ),
    takeEvery(
      actions.setActiveStepIndex.type,
      taskUpdateBookingUrlOnMoveToNextStep,
    ),
    takeEvery(ACTION_TYPES.FORM_FILL, taskSetCompleteOnFill),
    ...step1,
    ...step2,
    ...step3,
  ]);
}
