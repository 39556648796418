import { validateEmail } from 'core/redux/features/shared/validations';
import { addYears } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  BOOKING_STEP2,
  BOOKING_STEP3,
} from '../core/redux/features/booking/constants';
import {
  selectActiveBookingStep,
  selectBookingStepData,
} from '../core/redux/features/booking/reducers';
import { BookingStep2 } from '../core/redux/features/booking/types';
import { selectActiveProduct } from '../core/redux/features/products/reducers/products';
import { AppState } from '../core/redux/reducers';

var __SNAPTR_INIT__ = null;

const snapChatTrack = (...args: any) => {
  if (typeof window.snaptr !== 'undefined') {
    window.snaptr(...args);
  }
};

export const snapChatInit = (userEmail: string, options: Record<any, any>) => {
  if (__SNAPTR_INIT__ === userEmail) {
    return;
  }

  __SNAPTR_INIT__ = userEmail;

  snapChatTrack('init', '27d5b52a-9f8c-4ee4-8fdd-83fa7e9f79c4', {
    ...options,
    user_email: userEmail,
  });

  if (process.env.NODE_ENV !== 'production') {
    console.log('snap chat: init', userEmail);
  }
};

export const snapchatPageView = (
  productId?: string | number,
  productType?: string | number,
) => {
  let options: Record<string, any> = {};

  if (productId) {
    options['item_ids'] = [productId];
  }

  if (productType) {
    options['item_category'] = productType;
  }

  snapChatTrack('track', 'PAGE_VIEW', options);

  if (process.env.NODE_ENV !== 'production') {
    console.log('snap chat: PAGE_VIEW', options);
  }
};

export const snapchatViewContent = (
  productId: string | number,
  productType: string | number,
) => {
  snapChatTrack('track', 'VIEW_CONTENT', {
    item_category: productType,
    item_ids: [productId],
  });

  if (process.env.NODE_ENV !== 'production') {
    console.log('snap chat: VIEW_CONTENT', {
      item_category: productType,
      item_ids: [productId],
    });
  }
};

export const snapchatAddToCart = (
  productId: string | number,
  price: string | number,
  productType: string | number,
  currency: string = window.__defaultLocaleData__.currency,
) => {
  snapChatTrack('track', 'ADD_CART', {
    currency: currency,
    price: price,
    item_category: productType,
    item_ids: [productId],
  });

  if (process.env.NODE_ENV !== 'production') {
    console.log('snap chat: ADD_CART', {
      currency: currency,
      price: price,
      item_category: productType,
      item_ids: [productId],
    });
  }
};

export const snapchatPurchase = (
  productId: string | number,
  price: string,
  transactionId: number | string,
  productType: string,
  currency: string = window.__defaultLocaleData__.currency,
) => {
  snapChatTrack('track', 'PURCHASE', {
    currency: currency,
    price: price,
    transaction_id: transactionId,
    item_category: productType,
    item_ids: [productId],
  });

  if (process.env.NODE_ENV !== 'production') {
    console.log('snap chat: PURCHASE', {
      currency: currency,
      price: price,
      transaction_id: transactionId,
      item_category: productType,
      item_ids: [productId],
    });
  }
};

export const useSnapChatInit = () => {
  const [cookie, setCookie] = useCookies(['_ebsem']);
  const [isInit, setIsInit] = useState(__SNAPTR_INIT__ !== null);
  useEffect(() => {
    const email = cookie?._ebsem?.e;
    if (!email) {
      return;
    }

    snapChatInit(email, {});
    setIsInit(true);
  }, [cookie]);

  const { data: guestData, complete: isStep2Complete } = useSelector(
    (state: AppState) => selectBookingStepData(state, BOOKING_STEP2),
  ) as BookingStep2;

  const activeBookingStep = useSelector(selectActiveBookingStep);

  useEffect(() => {
    if (!isStep2Complete || activeBookingStep !== BOOKING_STEP3) {
      return;
    }
    const email = guestData.email;
    if (email != null && email.length && validateEmail(email)) {
      setCookie(
        '_ebsem',
        { e: email },
        {
          expires: addYears(new Date(), 1),
          path: '/',
        },
      );
    }
  }, [isStep2Complete, guestData.email, activeBookingStep, setCookie]);

  return [isInit];
};

const nextLocPath = (loc: Record<any, any>) => loc.pathname + loc.search;
export const useSnapChatPageView = () => {
  const loc = useLocation();
  const [isInit] = useSnapChatInit();
  const lastPathRef = useRef();

  const activeProduct = useSelector(selectActiveProduct);
  const viewInfo = useSelector((state: AppState) => state.products.viewInfo);

  useEffect(() => {
    const newPath = nextLocPath(loc);

    if (!isInit || lastPathRef.current === newPath) {
      return;
    }

    let productId = null;
    let productType = null;

    if (activeProduct && viewInfo) {
      productType = activeProduct.product_type;
      productId = activeProduct.id;
    }

    snapchatPageView(productId, productType);
    lastPathRef.current = newPath;
  }, [isInit, loc, activeProduct, viewInfo]);
};

export const useSnapChatAddToCart = () => {
  const product = useSelector(selectActiveProduct);
  const { activeStep, order } = useSelector((state: AppState) => ({
    activeStep: state.booking.activeStepIndex,
    order: state.booking.order,
  }));

  const [isInit] = useSnapChatInit();

  useEffect(() => {
    if (!isInit || activeStep !== BOOKING_STEP3) {
      return;
    }

    const price = order.total;
    const orderId = order.hold_id;
    const productType = product.product_type;
    const productId = product.id;

    if (!price || !orderId || !productType || !productId) {
      return;
    }
    snapchatAddToCart(productId, price, productType);
  }, [activeStep, order, isInit, product.product_type, product.id]);
};

export const useSnapChatTrackPurchase = () => {
  const [isInit] = useSnapChatInit();
  const lastOrderId = useRef<any>();

  useEffect(() => {
    if (!isInit) {
      return;
    }
    const currency = window.__pixel_data?.currency;
    const price = window.__pixel_data?.total;
    const orderId = window.__pixel_data?.order_id;
    const productType = window.__pixel_data?.product_type;
    const productId = window.__pixel_data?.restaurant_id;

    if (
      !currency ||
      !price ||
      !orderId ||
      !productType ||
      lastOrderId.current === orderId
    ) {
      return;
    }

    lastOrderId.current = orderId;

    snapchatPurchase(productId, price, orderId, productType, currency);
  }, [isInit]);
};

export const useSnapChatViewContentRestaurantInfo = () => {
  const [isInit] = useSnapChatInit();
  const activeProduct = useSelector(selectActiveProduct);
  // const viewInfo = useSelector((state: AppState) => state.products.viewInfo);

  useEffect(() => {
    if (!isInit || !activeProduct) {
      return;
    }

    const productType = activeProduct.product_type;
    const productId = activeProduct.id;

    snapchatViewContent(productId, productType);
  }, [isInit, activeProduct]);
};
