import React, { Fragment } from 'react';
import { Route } from 'react-router';

interface IProps {
  component: React.ComponentType<any>;
}

const ProductRoutes = (props: IProps) => {
  return (
    <Fragment>
      <Route
        exact
        path={[
          // english

          '/en/bar/:slug',
          '/en/restaurant/:slug',
          '/en/takeaway/:slug',

          // list view
          '/en/:city/bars',
          '/en/:city/restaurants',
          '/en/:city/takeaway',

          // map view
          '/en/map/:city/bars',
          '/en/map/:city/restaurants',
          '/en/map/:city/takeaway',

          // danish

          '/restaurant/:slug',
          '/bar/:slug',
          '/takeaway/:slug',

          // list view
          '/:city/restauranter',
          '/:city/restauranger',

          '/:city/barer',
          '/:city/takeaway',

          // map view
          '/kort/:city/restauranter',
          '/kort/:city/restauranter',

          '/kort/:city/barer',
          '/kort/:city/takeaway',

          '/karta/:city/restauranger',
          '/karta/:city/restauranger',

          '/karta/:city/barer',
          '/karta/:city/takeaway',
        ]}
        component={props.component}
      />
    </Fragment>
  );
};

export default ProductRoutes;
