export default {
  routes: {
    // product page
    restaurant: '/restaurant/:slug',
    bar: '/bar/:slug',

    // product list by city
    bar_by_city: '/:city/barer',
    restaurant_by_city: '/:city/restauranter',

    // product map by city
    bar_by_city_map: '/kort/:city/barer',
    restaurant_by_city_map: '/kort/:city/restauranter',

    takeaway: '/takeaway/:slug',
    takeaway_by_city_map: '/kort/:city/takeaway',
    takeaway_by_city: '/:city/takeaway',

    // product checkout
    book: '/book/:step/:productType/:slug',

    giftcard: '/gavekort/:step',
  },

  people: 'Personer',
  persons: 'Personer',
  person: 'Person',
  people_not_possible: 'IKKE MULIGT',
  time_not_possible: 'IKKE MULIG',
  all_people: 'Alle Personer',
  select_people: 'Select people',
  choose_people: 'Vælg antal personer',

  time_suffix: 'I DAG',
  select_time: 'Vælg ankomst tidspunkt',
  choose_time: 'Ankomst tidspunkt',
  choose_time_restaurant: 'Ankomst tidspunkt',
  choose_time_bar: 'Ankomst tidspunkt',
  choose_time_takeaway: 'Afhentningstidspunkt',

  choose_menu: 'Vælg :num menuer',

  view_menu: 'Vis menuer',
  order: 'BESTILLING',

  next: 'Næste',
  cancel: 'Afbryd',
  accept: 'Accepter',

  // filter header
  total_filtered: ':productType, som matcher din filtrering',
  total_filtered_mobile: [
    'Dagens restauranter, som matcher din filtrering:',
    '',
  ],
  // giftcard
  giftcard_item: ':quantity GAVEKORT :price',
  giftcard: {
    title: 'gavekort',
    amount: 'Skriv værdien på gavekortet',
    quantity: 'Antal',
    name: 'Dit navn',
    email: 'Din e-mail adresse',
    email_confirm: 'Venligst indtast din e-mail adresse igen',
    accept_terms: 'Jeg accepterer',
    accept_term_link: 'handelsbetingelserne',
  },

  overlay_title_showcase: 'IKKE AKTIV I DAG',
  overlay_title_soldout: 'UDSOLGT I DAG',
  overlay_subtitle: 'BLIV FRISTET AF EN AF DE ANDRE :productType',

  //errors
  errors: {
    menu_table_size_limit:
      ':menu_name kan kun bestilles af 2, 4 eller 6 personer',
    'modal-title': ' ',
    menus_num_person: 'Vælg menuer til :num_person personer',
    menu_select_person:
      'Venligst vælg :num_person menuer før du kan fortsætte',
    time: 'Venligst vælg en ankomst tid for din booking',
    num_person: 'Venligst vælg en ankomst tid for din booking',
    menus: 'Venligst vælg de menuer du ønsker.',
    name: 'Indtast venligst navn',
    email: 'Indtast venligst din e-mail',
    phone: 'Indtast venligst dit mobil nr',
    'gc-mail-phone':
      'Venligst indtast din e-mail og mobil nr. før du bruger koden',
    'gc-exist': 'Gavekort er allerede tilføjet',
    'gc-invalid': 'Ugyldigt gavekort',
    'error-gc-expired':
      'Udløbsdatoen for koden er overskredet, og koden er derfor ikke længere aktiv.',
    'error-matas-card': 'ClubMatas nr. er ugyldigt',
    'error-gc-status': 'Koden er brugt, og derfor ikke gyldig',
    'error-gc-user': 'Koden kan ikke bruges på dette sted.',
    'error-gc-newuser':
      'Koden gælder kun til nye kunder, som køber for første gang på earlybird.dk',
    'error-gc-maxuse':
      'Du har tidligere brugt rabatkoden og kan ikke bruge den igen.',
    'error-gc-resto': 'Koden virker ikke på dette sted',
    giftcard_amount:
      'Skriv Den Værdi, Som Du Ønsker Skal Stå På Gavekortet.',
    email_confirm: 'E-mail Adresserne Er Ikke Identiske',
    accept_terms: 'You must accept to the Terms and Conditions',
    'bycode-giftcard_validate_cover':
      'Koden er kun gyldig for :cover personer',
    'bycode-giftcard_validate_user':
      'Koden er desværre kun gyldig for nye kunder',
    'bycode-giftcard_validate_status': 'Gavekortet er allerede brugt',
    'bycode-giftcard_validate_expired': 'Desværre er koden udløbet',
    'bycode-giftcard_validate_resto':
      'Gavekoden gælder desværre ikke til dette sted',
    'bycode-giftcard_validate_maxuse':
      'Du har allerede brugt koden, og du kan ikke bruge den igen',
    no_table_available:
      'Øv, det sidste bord er netop solgt, så derfor kan du ikke gennemføre købet',
    coop_code: 'UGYLDIGT MEDLEMSNUMMER',
    coop_zip: 'Ugyldigt postnummer',
  },

  line_item: {
    menus: 'MENUER',
    tax: 'MOMS UDGØR 25%',
    admin_fee: 'ADMINISTRATIONSGEBYR',
    payment_total: 'BETALING I ALT',
    giftcode: 'GAVEKORTKODE',
    giftcard_item: ':quantity GIFT CARD :price',
    giftcard_total: 'I ALT',
    coop: 'COOP MEDLEM NR',
    zip_code: 'Post nr',
  },

  buttons: {
    ok: 'OK',
    cancel: 'AFBRYD',

    yes: 'Ja',
    no: 'Nej',
  },

  remove_gcode_confirm: 'Vil du fjerne koden?',

  filter_reasons: {
    times: 'Tidspunkt',
    area: 'Områder',
    person: 'Person',
    tags: 'Køkken & Kitchen stye',
  },

  book_table: 'BOOK BORD',
  book_table_bar: 'BOOK',
  book_table_restaurant: 'BOOK BORD',
  book_table_takeaway: 'BESTIL',

  book_table_sold: 'UDSOLGT',

  product_page: {
    the_restaurant: 'OM RESTAURANT',
    menu: 'MENU',
  },

  cut_price: 'DU FÅR ALTID 1/3 SKÅRET AF PRISEN MED EARLY BIRD ',

  arrival_time_restaurant: 'Ankomst tid',
  arrival_time_bar: 'Ankomst tid',
  arrival_time_takeaway: 'Afhentningstid',

  filter_area: 'Områder',

  times_of_today: 'TIDER I DAG',
  times_of_today_bar: 'TIDER I DAG',
  times_of_today_restaurant: 'TIDER I DAG',
  times_of_today_takeaway: 'AFHENTNINGSTIDER I DAG',

  show_menus: 'VIS MENU',
  the_resto: 'Om Restaurant',
  the_bar: 'Om Baren',
  gallery: 'Galleri',
  reset_filter: 'NULSTIL FILTER',
  save_search: 'VIS :total RESULTATER',
  viewtype_list: 'liste',
  viewtype_map: 'Kort',
  address: 'Find vej',
  use: 'BRUG',
  fullname: 'NAVN',
  emailfield: 'E-MAIL',
  phonenum: 'MOBILNUMMER',
  giftcode: 'GAVEKORT & RABATKODER',
  subscribe:
    'Tilmeld mig nyhedsbrevet med nye spændende restauranter. Sendes ca. 2 gange om måneden og kan nemt afmeldes.',
  map_address: 'Adresse',
  selected: 'valgt',
  see_product: 'SE DAGENS :product',

  BOOK: 'BOOK',
  INFO: 'INFO',
  TERMS: 'VILKÅR',
  PAYMENT: 'BETALING',

  order_giftcard: 'Køb af gavekort',

  filter_matcher: {
    doesnt: 'SØGNING',
    search_on: 'MATCHER IKKE PÅ',
  },

  choose: 'VÆLG',
  see_todays: 'Se dagens',
  see_todays_in: 'i',
  more_menu: 'andre menuer',
  filter_unselectall: 'FRAVÆLG ALLE',
  filter_save: 'GEM',
  payment_failed_error_msg: 'Betalingen fejlede. Prøv venligst igen.',
  how_many_are_you: 'Hvor mange er I?',
  select: 'Vælg',
  gc_success_msg: ':code er nu fratrukket betalingsprisen',
  '2 people': '2 personer',
  '3 people': '3 personer',
  '4 people': '4 personer',
  '5 people': '5 personer',
  '6 people': '6 personer',
  '7 people': '7 personer',
  '8 people': '8 personer',
  change_parent_city: 'Skift til :city',
  view_on_map: 'Se på kort',

  underserving_tag:
    'Udendørsservering er muligt, men kan ikke reserveres.\n Spørg i restauranten ved ankomst.',
  menu_select_entire_table: 'Denne menu skal vælges af hele bordet.',

  menu_type_text: {
    lunch: 'Menuen serveres kun til frokost.',
    dinner: 'Menuen serveres kun om aftenen.',
  },
  no_times: 'Ingen ledige tider',
  read_more: 'Læs mere',
  show_all_resto: 'Vis alle restauranter',
  search: 'Søg',
  remaining_gc_notice:
    'Et gavekort på de resterende :value kr. sendes til :email, når ordren er gennemført.',
  message: {
    all_hold_warning:
      'Lige nu er en anden i gang med at købe den sidste plads,\n og derfor er det ikke muligt at fortsætte denne bestilling.',
  },
  back: 'Tilbage',
  all: 'Alle',
  zip_code: 'Post nr.',
  coop_mem_no: 'Coop medlem nr.',
  coop_member_registered: 'DIT MEDLEMSNUMMER ER REGISTRERET',
  sweden_vat: 'Priserna är i SEK och inkl. moms.',
  menu: 'Menu',
  go_to_payment: "Fortsæt til betaling",
  booking_restaurant_slug: 'restaurant',
  home: 'Forside',
};
