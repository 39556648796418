import sharedActions from 'core/redux/features/shared/actions';
import { addMinutes } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../core/redux/features/booking/actions';
import { selectBookingSeats } from '../../core/redux/features/booking/reducers';
import { AppState } from '../../core/redux/reducers';

const HOLD_COOKIE_KEY = '_ebhold';
const HOLD_DURATION = 15;
export const bookingHoldIdFromCookie = (): number | undefined => {
  const val = new Cookies().get(HOLD_COOKIE_KEY)?.v;

  if (val && typeof val !== 'number') {
    return parseInt(val);
  }

  return val;
};

export const bookingUpdateHoldIdFromCookie = dispatch => {
  const holdIdFromCookie = bookingHoldIdFromCookie();

  if (!holdIdFromCookie) {
    return;
  }

  dispatch(actions.updateHoldId(holdIdFromCookie));

  return holdIdFromCookie;
};

export const useHandleBookingHoldId = () => {
  const [cookie, setCookie] = useCookies([HOLD_COOKIE_KEY]);
  const dispatch = useDispatch();
  const booking = useSelector((state: AppState) => state.booking);
  const lastHoldId = useRef<number>(0);

  // save hold id to cookie
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const holdId = booking.order.hold_id;
    const prevHoldId = lastHoldId.current;

    if (!holdId || prevHoldId === holdId) {
      return;
    }

    setCookie(
      HOLD_COOKIE_KEY,
      { v: holdId },
      {
        expires: addMinutes(new Date(), HOLD_DURATION),
        path: '/',
      },
    );

    lastHoldId.current = holdId;
  }, [booking.order.hold_id, cookie, setCookie]);

  // fill hold id from cookie if hold id is not set
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const holdId = booking.order.hold_id;
    if (holdId) {
      return;
    }

    let holdIdFromCookie = bookingUpdateHoldIdFromCookie(dispatch);
    if (!holdIdFromCookie) {
      return;
    }

    lastHoldId.current = holdIdFromCookie;
  }, [booking.order.hold_id, cookie, dispatch]);
};

export const isRemainingStockOnHold = (obj: Record<any, any>[]) => {
  const allStatus = obj.map(item => item?.status);
  const hasStock = allStatus.indexOf('in-stock') > -1;
  if (hasStock) {
    // one of seat are in stock no need to show popup
    return false;
  }

  const onHold = allStatus.indexOf('on-hold') > -1;
  if (!onHold) {
    // we can assume here that all of seat are soldout
    return false;
  }

  return true;
};
export const useShowPopupLastStockOnHold = () => {
  const {
    entities: { seats },
  } = useSelector(selectBookingSeats);
  const {
    timeList: {
      entities: { times },
    },
  } = useSelector((state: AppState) => ({
    timeList: state.booking.times,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const isSeatOnHold = seats
      ? isRemainingStockOnHold(Object.values(seats))
      : false;
    const isTimeOnHold = times
      ? isRemainingStockOnHold(Object.values(times))
      : false;

    if (isSeatOnHold || isTimeOnHold) {
      dispatch(sharedActions.notifyAlert('message.all_hold_warning'));
    }

    // one or more seat are on hold
  }, [dispatch, seats, times]);
};
