import {
  createPaginationDefaultState,
  createPaginationSlice,
  IPaginationState,
} from '../shared/paginated';
import { ProductId } from '../shared/types';
import { getPartners } from '../../../api/earlybird';

export type PartnerStateData = {
  id: ProductId;
  name: string;
  slug: string;
  product_type: string;
  weight: number;
  primary_image: string;
  mobile_primary_image: string;
  short_description: string;
};

export type IPartnersState = IPaginationState<PartnerStateData>;

export const partnersDefaultState: IPartnersState =
  createPaginationDefaultState<PartnerStateData>({
    results: {
      all: {
        loading: false,
        params: {},
        current_page: 1,
        next_page: 1,
        per_page: 6,
        total_data: 0,
        data: [],
      },
    },
  });

const partnerSlice = createPaginationSlice(
  'partners',
  partnersDefaultState,
  getPartners,
);

const reducers = {
  partners: partnerSlice.reducers,
};

const actions = partnerSlice.actions;
const sagas = partnerSlice.sagas;

export { reducers, actions, sagas };
