import { differenceInMinutes, parseISO } from 'date-fns';
import { schema } from 'normalizr';

const DATENOW = parseISO(window.__serverdate__) || new Date();
// api schema for earlybird v4

// Stock seat listing
export const seats = new schema.Entity(
  'seats',
  {},
  {
    idAttribute: 'seat',
    processStrategy: entity => entity,
  },
);
export const seatList = new schema.Array(seats);

// Stock time listing
export const fd_time = new schema.Entity(
  'times',
  {},
  {
    idAttribute: 'time',
    processStrategy: entity => entity,
  },
);
export const timeList = new schema.Array(fd_time);

// categories and tags

export const categoryTag = new schema.Entity(
  'tags',
  {},
  {
    processStrategy: (entity, _parent, _key) => {
      // remove icon property
      const { icon, ...emits } = entity;
      return emits;
    },
  },
);

export const category = new schema.Entity(
  'categories',
  {
    tags: [categoryTag],
  },
  {
    processStrategy: (entity, _parent, _key) => {
      return entity;
    },
  },
);
export const categories = new schema.Array(category);

export const sortOption = new schema.Entity(
  'sorts',
  {},
  {
    idAttribute: 'link',
    processStrategy: e => ({
      ...e,
      id: e.link,
      label: e.title,
    }),
  },
);
export const sortOptions = new schema.Array(sortOption);

// ------
// products
/*
products: {'product entity'}
menus: {'menu entity'}
types: {
  [product type]: ['entity ids']
}
slugs: {
  [slug]: {entity_id': 'entity_id', slug': 'slug'}
}
*/
// ------

export const menuCourse = new schema.Entity('courses');
export const menuCourses = new schema.Array(menuCourse);

export const menuInfoProduct = new schema.Entity(
  'products',
  {},
  {
    mergeStrategy: (a, b) => {
      if (a.id === b.id) {
        a.menus.push(...b.menus);
      }
      return a;
    },
  },
);

export const menuInfo = new schema.Entity(
  'menus',
  {
    courses: menuCourses,
    restaurant_id: menuInfoProduct,
  },
  {
    processStrategy: (e, p) => {
      return {
        ...e,
        time_start: e?.time_start ?? '00:00:00',
        time_end: e?.time_end ?? '23:59:59',
        courses: e?.courses ?? e?.course,
        restaurant_id: {
          // we will do this to create entity for products
          id: e.restaurant_id ?? p.id,
          menus: [e.id],
        },
      };
    },
  },
);
export const menuInfos = new schema.Array(menuInfo);

export const menu = new schema.Entity(
  'menus',
  {},
  {
    idAttribute: e => {
      if (
        e.hasOwnProperty('restaurant_child_menu_id') &&
        e.restaurant_child_menu_id > 0
      ) {
        return e.restaurant_child_menu_id;
      } else if (e.hasOwnProperty('restaurant_menu_id')) {
        return e.restaurant_menu_id;
      } else {
        return e.id;
      }
    },
    processStrategy: (e, p) => {
      let menuId: number;
      if (
        e.hasOwnProperty('restaurant_child_menu_id') &&
        e.restaurant_child_menu_id > 0
      ) {
        menuId = e.restaurant_child_menu_id;
      } else if (e.hasOwnProperty('restaurant_menu_id')) {
        menuId = e.restaurant_menu_id;
      } else {
        menuId = e.id;
      }

      return {
        ...e,
        time_start: e?.time_start ?? '00:00:00',
        time_end: e?.time_end ?? '23:59:59',
        id: menuId,
        name: e.hasOwnProperty('restaurant_menu_name')
          ? e.restaurant_menu_name
          : e.name,
        price_regular: parseFloat(e.price_regular),
        price_sale: parseFloat(e.price_sale),
        status: e.hasOwnProperty('menu_status') ? e.menu_status : e.status,
        weight: e.weight,
        route: `${p.route}?view=menu`,
      };
    },
  },
);

export const menus = new schema.Array(menu);

export const productType = new schema.Entity(
  'types',
  {},
  {
    idAttribute: e => e.product_type,
    mergeStrategy: (ea, eb) => {
      if (Array.isArray(eb)) {
        eb = eb[0];
      }

      // merge all entity id in one array
      if (typeof ea === 'string') {
        return [ea, eb];
      }
      return [...ea, eb];
    },
    processStrategy: (_e, p) => [p.id],
  },
);

export const productSlug = new schema.Entity(
  'slugs',
  {},
  {
    idAttribute: e => e.slug,
    processStrategy: (e, p) => ({
      id: p.id,
      slug: e.slug,
      type: p.product_type,
    }),
  },
);

const productParser: {
  idAttribute: schema.SchemaFunction;
  processStrategy: schema.StrategyFunction<any>;
} = {
  idAttribute: e => e.id || e.restaurant_id, // make sure id is always string
  processStrategy: item => {
    const _id = item.id || item.restaurant_id;
    const _productType = item.product_type;
    const links = item?.internal_links;

    const menuList = [];
    let allMenus = item.menu_list || [];

    if (!Array.isArray(allMenus)) {
      allMenus = Object.values(allMenus);
    }

    allMenus
      .map(menu => {
        if (
          menu.hasOwnProperty('restaurant_menu_id') &&
          menu.restaurant_menu_id > 0
        ) {
          return menu.restaurant_menu_id;
        }

        return menu.id;
      })
      .filter((menuId, arrIndex, arr) => {
        return arr.indexOf(menuId) === arrIndex;
      })
      .forEach((_validMenuId, arrIndex) => {
        menuList.push(allMenus[arrIndex]);
      });

    let isAllTimeSold = true;
    const timeList = [];
    const allTimes = (item.time_list || []).map((t: any) => {
      const isSoldout =
        differenceInMinutes(parseISO(t.valid_until), DATENOW) <= 0;

      let status = t.time_status;
      if (
        t.time_status === 'in-stock' &&
        item.status === 'in-stock' &&
        isSoldout
      ) {
        status = 'soldout';
      }

      if (status === 'in-stock') {
        isAllTimeSold = false;
      }

      return {
        time: t.fd_time,
        time_end: t.fd_time_end,
        cover: parseInt(t.cover),
        status: status,
        seats:
          t.time_status !== 'in-stock' || item.status !== 'in-stock'
            ? []
            : t.seats.map((t: any) => parseInt(t)),
        valid_until:
          t.time_status !== 'in-stock' || item.status !== 'in-stock'
            ? false
            : t.valid_until,
      };
    });

    allTimes
      .map(item => item.time)
      .filter((time, arrIndex, arr) => {
        return arr.indexOf(time) === arrIndex;
      })
      .forEach((_, arrIndex) => {
        timeList.push(allTimes[arrIndex]);
      });

    return {
      ...item,
      status:
        item.status === 'in-stock' && isAllTimeSold ? 'soldout' : item.status,
      id: _id,
      // make sure the server returns array of internal links
      internal_links:
        links && !Array.isArray(links) ? Object.values(links) : links || [],
      // name: item.name,
      route: `/${_productType}/${item.slug}`,
      // status: item.status,
      is_active: item.status === 'in-stock',
      price_regular: item.stock_price_regular || 0,
      price_sale: item.stock_price_sale || 0,
      product_type: {
        entity_id: _id,
        product_type: _productType,
      },
      // short_description: item.short_description,
      // description: item.description,
      slug: {
        slug: item.slug,
      },
      city_address: item?.city_address || item?.city_name,
      // weight: item.weight,
      // city_id: item.city_id,
      // parent_city_id: item.city_parent_id,
      created_at: item.restaurant_created_at,
      updated_at: item.restaurant_updated_at,
      latest_menu_created_at: item.latest_restaurant_menu_date,
      geo: {
        lat: item.lat,
        lng: item.long,
      },
      primary_images: item.hasOwnProperty('alternative_images')
        ? Object.keys(item.alternative_images).map(key => ({
            url: item.alternative_images[key],
            alt: key,
            type: key,
            weight: 0,
          }))
        : [],
      menus: menuList,
      seats: (item.seat_list || item.seats || []).map(
        (i: {
          seat: string;
          seat_status?: any;
          status?: any;
          times?: any;
          time_list?: any;
        }) => ({
          seat: parseInt(i.seat),
          status: i.seat_status || i.status || 'soldout',
          time_list: i.times || i.time_list,
        }),
      ),
      tags: item.hasOwnProperty('tags')
        ? item.tags.map((tagId: any) => ({
            id: tagId,
          }))
        : [],
      times: timeList,
    };
  },
};

export const product = new schema.Entity(
  'products',
  {
    menus: menus,
    product_type: productType,
    slug: productSlug,
  },
  productParser,
);
export const products = new schema.Array(product);

export const productInfo = new schema.Entity(
  'products',
  {
    menus: menuInfos,
    product_type: productType,
    slug: productSlug,
  },
  productParser,
);
export const productInfos = new schema.Array(productInfo);
