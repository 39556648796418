export interface ActionPayload<P, T = string, M = Record<string, any>> {
  type: T;
  payload: P;
  meta?: M;
}

export interface ActionCreatorType<T = string> {
  type: T;
  toString: () => T;
}

export type ActionPayloadCreatorNoMeta<P, A extends any[] = any[]> = (
  ...args: A
) => { payload: P };

export type ActionPayloadCreator<
  P,
  M extends Record<string, any> = Record<string, any>,
  A extends any[] = any[],
> = (...args: A) => { payload: P; meta: M };

/**
 * Helper function for creating action
 * @param type
 * @param payloadCreator
 *
 * usage:
 * const actionCreateItem = createAction<string>('CREATE_ITEM')
 * actionCreateItem('this is a sample') // returns {type: 'CREATE_ITEM', payload: 'this is a sample'}
 *
 * usage with payload creator:
 * type ItemType = {
 *   id: number,
 *   title: string,
 *   content: string,
 *   created_at: string,
 * }
 * const actionCreateItem = createAction<ItemType>('CREATE_ITEM', function(title: string) {
 *   return {
 *     payload: {
 *       id: generateRandomNumber(),
 *       title,
 *       created_at: (new Date).toISOString()
 *     }
 *   }
 * })
 *
 * actionCreateItem('My sample') // returns {type: 'CREATE_ITEM', payload: {id: 123, title: 'My sample', created_at: 2020-01-18T19:39:45.861Z}}
 */
/**
 * Action with type only
 * @param type
 */

// function createAction<P>(type: string): any
/**
 * Action with type only
 * @param type
 */
// function createAction(type: string): {
//   (): ActionPayload<undefined>
// } & ActionCreatorType
/**
 * Action with payload type and without creator
 * @param type
 * @param payloadCreator
 */
// function createAction<P, T = string>
// (type: T, payloadCreator: () => { payload: P }): {
//   (): ActionPayload<P>
// } & ActionCreatorType & T
/**
 * Action with payload type and without creator
 * @param type
 */
function createAction(type: string): {
  (): ActionPayload<never>;
} & ActionCreatorType &
  string;
/**
 * Action with payload creator
 * @param type
 */
function createAction<P>(type: string): {
  (payload: P): ActionPayload<P>;
} & ActionCreatorType &
  string;

function createAction<
  P,
  PC extends ActionPayloadCreatorNoMeta<P> = ActionPayloadCreatorNoMeta<P>,
  T = string,
>(
  type: T,
  payloadCreator?: PC,
): {
  (...payload: Parameters<PC>): ActionPayload<ReturnType<PC>['payload'], T>;
} & ActionCreatorType<T> &
  T;

function createAction<
  P,
  PC extends ActionPayloadCreator<P> = ActionPayloadCreator<P>,
  T = string,
>(
  type: T,
  payloadCreator?: PC,
): {
  (
    ...payload: Parameters<PC>
  ): ActionPayload<ReturnType<PC>['payload'], T, ReturnType<PC>['meta']>;
} & ActionCreatorType<T> &
  T;

function createAction(type: string, payloadCreator?: Function): any {
  function actionCreator(...args: any[]) {
    if (payloadCreator) {
      const createdPayload = payloadCreator(...args);

      // validate create result
      // creator should always return object with key payload
      if (!createdPayload || !createdPayload.hasOwnProperty('payload')) {
        throw new Error('creator should return object with key payload');
      }

      return {
        type,
        ...createdPayload,
      };
    }

    return {
      type,
      payload: args.length > 0 ? args[0] : undefined,
    };
  }

  actionCreator.type = type;
  actionCreator.toString = () => type;

  return actionCreator;
}

export default createAction;
