import { createSelector } from 'reselect';
import { _get } from '../../../utils/object-prop';
import createReducer from '../../helpers/createReducer';
import { AppState } from '../../reducers';
import { selectTranslator } from '../languages/trans';
import { IFilterOption, IStep } from '../shared/types';
import { validateEmail } from '../shared/validations';
import actions from './actions';
import { BOOKING_STEP1, BOOKING_STEP2, BookingInitialSate } from './constants';
import {
  BookingStep1,
  BookingSteps,
  IBookingSeatList,
  IBookingState,
} from './types';
import {
  validateCoopCode,
  validateCoopZip,
  validateStep1,
  validateTime,
} from './validations';

function handleStepFillForm(state, step, data) {
  if (!state.form_data[step]) {
    return state;
  }

  // accept only defined field in initial state
  Object.entries(data).forEach(([field, val]) => {
    if (state.form_data[step]['data'].hasOwnProperty(field)) {
      state.form_data[step]['data'][field] = val;
    }

    // remove lat error
    if (state.form_data[step].errors.hasOwnProperty(field)) {
      delete state.form_data[step].errors[field];
    }
  });

  return state;
}

/**
 * Booking reducer
 * TODO: simply form_data
 */
export const reducers = createReducer<IBookingState>(BookingInitialSate, {
  [actions.loading]: function (
    state,
    action: ReturnType<typeof actions.loading>,
  ) {
    state.loading = action.payload;
    return state;
  },
  [actions.moveToNextStep]: function (state) {
    if (state.activeStepIndex !== Object.values(state.steps).length - 1) {
      state.activeStepIndex = state.activeStepIndex + 1;
    }
    state.loading = false;
    return state;
  },
  /**
   * Handle select number of person/guest
   * @param state
   * @param numPerson
   */
  [actions.selectNumberOfPerson]: function (
    state,
    { payload: numPerson }: ReturnType<typeof actions.selectNumberOfPerson>,
  ) {
    // mark step to incomplete
    state.form_data[BOOKING_STEP1]['data']['complete'] = false;

    // set selected person
    state.form_data[BOOKING_STEP1]['data']['num_person'] = numPerson;

    // reset time and menus selection
    state.form_data[BOOKING_STEP1]['data']['time'] = '0';
    state.form_data[BOOKING_STEP1]['data']['menus'] = {};

    // generate number of person list for product menus
    const personList: number[] = [0];
    if (numPerson > 0) {
      for (let num = 1; num <= numPerson; num++) {
        personList.push(num);
      }
    }

    state.seat_menu_options = personList;
    return state;
  },
  /**
   * Handle guest choose time
   * @param state
   * @param payload
   */
  [actions.selectTime]: function (
    state,
    { payload }: ReturnType<typeof actions.selectTime>,
  ) {
    const valid = validateTime(payload.time);
    if (valid !== true) {
      state.form_data[BOOKING_STEP1].errors = {
        time: valid,
      };
    } else {
      state.form_data[BOOKING_STEP1]['data']['time'] = payload.time;
      state.form_data[BOOKING_STEP1]['data']['time_end'] = payload.time_end;
      state.form_data[BOOKING_STEP1]['data']['menus'] = {};
      state.form_data[BOOKING_STEP1]['data']['complete'] = false;
    }

    // revert to default state
    state.form_data[BOOKING_STEP1]['data']['menus'] = {};

    return state;
  },
  [actions.selectProductMenu]: function (
    state,
    { payload }: ReturnType<typeof actions.selectProductMenu>,
  ) {
    state.form_data[BOOKING_STEP1]['data']['menus'][payload.menu_id] = payload;
    state.form_data[BOOKING_STEP1]['data']['complete'] = false;
    return state;
  },
  /**
   * Handle update available time list
   * @param state
   * @param action
   */
  [actions.updateAvailableTimeList]: function (
    state,
    action: ReturnType<typeof actions.updateAvailableTimeList>,
  ) {
    state.times = action.payload;
    return state;
  },
  [actions.setStepErrors]: function (
    state,
    { payload }: ReturnType<typeof actions.setStepErrors>,
  ) {
    const { step, errors } = payload;
    state.form_data[step]['errors'] = errors;
    return state;
  },
  [actions.completeStep]: function (
    state,
    { payload }: ReturnType<typeof actions.completeStep>,
  ) {
    const { step, is_complete } = payload;
    state.form_data[step]['complete'] = is_complete;
    if (is_complete) {
      state.form_data[step].errors = {}; // clear errors
    }
    return state;
  },
  [actions.orderHold]: function (
    state,
    { payload }: ReturnType<typeof actions.orderHold>,
  ) {
    if (payload.line_items.length > 0) {
      payload.line_items = payload.line_items.sort(
        (a, b) => a.weight - b.weight,
      );
    }

    state.order = {
      ...state.order,
      ...payload,
    };
    return state;
  },
  [actions.setActiveStepIndex]: function (
    state,
    { payload }: ReturnType<typeof actions.setActiveStepIndex>,
  ) {
    state.activeStepIndex = payload;

    if (payload === BOOKING_STEP1) {
      state.form_data[BOOKING_STEP1]['data']['num_person'] = 0;
      state.form_data[BOOKING_STEP1]['data']['time'] = '0';
      state.form_data[BOOKING_STEP1]['data']['time_end'] = null;
      state.form_data[BOOKING_STEP1]['data']['menus'] = {};
      state.menus = {
        entities: {},
        result: [],
      };
      state.seats = {
        entities: {},
        result: [],
      };
      state.times = {
        entities: {},
        result: [],
      };
    } else if (payload === BOOKING_STEP2) {
      state.form_data[BOOKING_STEP2]['data']['giftcodes'] = [];
      state.form_data[BOOKING_STEP2]['data']['last_giftcode'] = '';
    }
    return state;
  },
  /**
   * @param state
   * @param payload
   */
  [actions.fillStep2Data]: function (
    state,
    { payload }: ReturnType<typeof actions.fillStep2Data>,
  ) {
    const { step, data } = payload;
    return handleStepFillForm(state, step, data);
  },
  [actions.fillForm]: function (
    state,
    { payload }: ReturnType<typeof actions.fillForm>,
  ) {
    const { step, data } = payload;
    return handleStepFillForm(state, step, data);
  },
  [actions.formSubmit]: function (
    state,
    { payload }: ReturnType<typeof actions.formSubmit>,
  ) {
    if (state.loading) {
      console.warn("it's not possible to submit while loading");
    }

    const { data, step } = payload;
    state = handleStepFillForm(state, step, data);

    // TODO: move all validation here if possible
    let validate = {};
    if (step === BOOKING_STEP1) {
      validate = validateStep1(state.form_data[step] as BookingStep1);
    }

    state.form_data[step].complete = Object.values(validate).length === 0;
    state.form_data[step].errors = validate;
    return state;
  },
  /**
   * Handle gift code validation request
   * it will also validate email and
   * the phone number will validated in saga because
   * the validation script is lazy loaded
   * @param state
   * @param giftcode
   * @param email
   * @param phone
   */
  [actions.giftcodeValidateRequest]: function (
    state,
    {
      payload: { giftcode, email, phone },
    }: ReturnType<typeof actions.giftcodeValidateRequest>,
  ) {
    if (!validateEmail(email)) {
      state.form_data[BOOKING_STEP2]['errors']['email'] = 'errors.email';
      return state;
    }

    state.form_data[BOOKING_STEP2]['data']['last_giftcode'] = giftcode;
    state.form_data[BOOKING_STEP2]['data']['email'] = email;
    state.form_data[BOOKING_STEP2]['data']['phone'] = phone;
    return state;
  },
  [actions.giftcodeValidateComplete]: function (
    state,
    { payload }: ReturnType<typeof actions.giftcodeValidateComplete>,
  ) {
    state.form_data[BOOKING_STEP2]['data']['giftcodes'].push(payload);
    state.form_data[BOOKING_STEP2]['data']['last_giftcode'] = '';
    return state;
  },
  [actions.giftcardRemoveRequest]: function (
    state,
    { payload }: ReturnType<typeof actions.giftcardRemoveRequest>,
  ) {
    state.form_data[BOOKING_STEP2]['data']['giftcodes'] = state.form_data[
      BOOKING_STEP2
    ]['data']['giftcodes'].filter(code => code !== payload);
    // return set(state, `error`, 'Code is already removed, Please try again')
    return state;
  },

  /**
   * handle seat list update
   */
  [actions.updateAvailableSeats]: function (
    state,
    { payload }: ReturnType<typeof actions.updateAvailableSeats>,
  ) {
    state.seats = payload;
    return state;
  },

  /**
   * Handle menu list updates
   * @param state
   * @param payload
   */
  [actions.updateAvailableMenuList]: function (
    state,
    { payload }: ReturnType<typeof actions.updateAvailableMenuList>,
  ) {
    state.menus = payload;
    return state;
  },

  [actions.updateHoldId]: function (
    state,
    { payload }: ReturnType<typeof actions.updateHoldId>,
  ) {
    state.order.hold_id = payload;
    return state;
  },

  [actions.coopCodeValidateRequest]: function (
    state,
    { payload }: ReturnType<typeof actions.coopCodeValidateRequest>,
  ) {
    let validaVals = [];
    if (payload.coopCode) {
      let code = Number(payload.coopCode);
      let validateCode = validateCoopCode(code);
      if (validateCode !== true) {
        state.form_data[BOOKING_STEP2]['errors']['coop_code'] = validateCode;
      } else {
        validaVals.push(payload.coopCode);
        state.form_data[BOOKING_STEP2]['data']['coop_code'] = payload.coopCode;
        state.form_data[BOOKING_STEP2]['errors']['coop_code'] = null;
      }
    } else {
      state.form_data[BOOKING_STEP2]['data']['coop_code'] = '';
      state.form_data[BOOKING_STEP2]['errors']['coop_code'] = null;
    }

    if (payload.coopZip) {
      let zip = Number(payload.coopZip);
      let validateZip = validateCoopZip(zip);
      if (validateZip !== true) {
        state.form_data[BOOKING_STEP2]['errors']['coop_zip'] = validateZip;
      } else {
        validaVals.push(payload.coopZip);
        state.form_data[BOOKING_STEP2]['data']['coop_zip'] = payload.coopZip;
        state.form_data[BOOKING_STEP2]['errors']['coop_zip'] = null;
      }
    } else {
      state.form_data[BOOKING_STEP2]['data']['coop_zip'] = '';
      state.form_data[BOOKING_STEP2]['errors']['coop_zip'] = null;
    }
    state.order.cards = [];

    if (validaVals.length === 2) {
      let [code, zip] = validaVals;
      state.order.cards.push({ card_number: code, card_type: 'coop' });
      state.order.billing_zipcode = zip;
    } else {
      state.order.billing_zipcode = null;
    }

    return state;
  },
});

export const selectActiveStepIndexFromRouteParams = createSelector(
  [
    (_state: AppState, params: { step: string }) => params.step,
    (state: AppState) => state.booking.steps,
  ],
  (stepRoute, steps): number => {
    return Object.values<IStep>(steps).findIndex(
      step => step.route === stepRoute,
    );
  },
);

export const selectBookingStepData = createSelector(
  [
    (_state: AppState, step: number) => step,
    (state: AppState) => state.booking.form_data,
  ],
  (activeStep: number, formData: Record<number, BookingSteps>) => {
    return _get(formData, activeStep, {});
  },
);

export const selectBookingSeats = (state: AppState) => state.booking.seats;

export const selectBookingSeatFilterOption = createSelector(
  [selectBookingSeats, selectTranslator],
  (
    { entities: { seats } }: IBookingSeatList,
    trans,
  ): IFilterOption<number>[] => {
    const person = trans('person');
    const persons = trans('persons');
    const notPossible = trans('people_not_possible');
    return [1, 2, 3, 4, 5, 6, 7, 8].map((seat): IFilterOption<number> => {
      let prefix = seat > 1 ? persons : person;
      const find = seats?.[seat];
      const isDisabled = !find || _get(find, 'status') !== 'in-stock';
      if (isDisabled) {
        prefix += ` (${notPossible})`;
      }

      return {
        id: seat,
        label: `${seat} ${prefix}`,
        disabled: isDisabled,
      };
    });
  },
);

export const selectActiveBookingStep = createSelector(
  [(state: AppState) => state.booking.activeStepIndex],
  step => step,
);

export const selectBookingMenus = createSelector(
  [(state: AppState) => state.booking.menus.entities?.menus],
  item => {
    if (!item) {
      return item;
    }

    return Object.values(item)
      .sort((a, b) => {
        const aSale = a.price_regular;
        const bSale = b.price_regular;
        return aSale === bSale ? 0 : aSale > bSale ? 1 : -1;
      })
      .sort((a, b) => {
        // a less than b
        if (a.menu_type === 'LUNCH' && b.menu_type !== 'LUNCH') {
          return 1;
        }

        // a greater than b
        if (a.menu_type !== 'LUNCH' && b.menu_type === 'LUNCH') {
          return -1;
        }

        return 0;
      });
  },
);

export default reducers;
