import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { AppState } from 'core/redux/reducers';
import { selectTranslator, TransFn } from 'core/redux/features/languages/trans';
import Products from '../Features/Products/loadable';
import Booking from '../Features/Booking';
import ProductRoutes from './ProductRoutes';
import Giftcard from '../Features/Giftcard';
import Partners from '../Features/Partners';
import Blogs from '../Features/Blogs';
import { IAreas } from 'core/redux/features/filters/types';

const RouteListComp = ({
  areas,
  parentAreas,
}: {
  trans: TransFn;
  areas: IAreas;
  parentAreas: IAreas;
}) => {
  const cityRoutes = parentAreas
    .map(area => {
      return areas.map(city => `/${area.link}/${city.link}/:tag?`);
    })
    .flat();

  return (
    <Fragment>
      {/*<Route exact path="/" component={Products} />*/}
      <Route
        exact
        path={[
          '/book/:step/:productType/:slug',
          '/en/book/:step/:productType/:slug',
        ]}
      >
        {({ match }) => {
          if (!match) {
            return null;
          }
          return <Booking />;
          // return (
          //   <CSSTransition
          //     timeout={250}
          //     in={match != null}
          //     classNames="page-transition"
          //     unmountOnExit
          //   >
          //     <div className="page-transition page-transition--booking">
          //       <Booking />
          //       <div className="page-transition__shadow" />
          //     </div>
          //   </CSSTransition>
          // );
        }}
      </Route>
      <Route
        exact
        path={[
          '/gavekort/:step',
          '/en/gavekort/:step',
          '/presentkort/:step',
          '/en/presentkort/:step',
        ]}
        component={Giftcard}
      />
      <Route
        exact
        path={[
          '/partnere/:city/:productType',
          '/en/partnere/:city/:productType',
          '/partners/:city/:productType',
          '/en/partners/:city/:productType',
        ]}
        component={Partners}
      />
      <Route exact path={['/guide', '/en/guide']} component={Blogs} />
      <ProductRoutes component={Products} />
      <Route exact path={cityRoutes} component={Products} />
    </Fragment>
  );
};

const configure = connect((state: AppState) => ({
  trans: selectTranslator(state),
  areas: state.filters.areas,
  parentAreas: state.parentAreas,
}))(RouteListComp);

export default configure;
