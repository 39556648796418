import { format } from 'date-fns';
import { createSelector } from 'reselect';
import { _get, mergeDeep } from '../../../../utils/object-prop';
import { AppState } from '../../../reducers';
import { ProductId } from '../../shared/types';
import {
  ADD_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO_MENUS,
  iniState,
} from '../constants';
import {
  IAddProductInfo,
  IProductInfo,
  IProductInfoEntity,
  IProductInfoMenu,
  IProductMenuCourse,
} from '../types';

export default (
  state: IProductInfoEntity = iniState.productInfos,
  action: IAddProductInfo,
): IProductInfoEntity => {
  switch (action.type) {
    case ADD_PRODUCT_INFO:
      return mergeDeep(state, action.payload.entities);
    case UPDATE_PRODUCT_INFO_MENUS:
      return mergeDeep(state, action.payload.entities);
    // const entities = action.payload.entities
    // if (entities.hasOwnProperty('menus')) {
    //   state.menus = mergeDeep(state.menus, action.payload.entities.menus)
    // }
    //
    // if (entities.hasOwnProperty('courses')) {
    //   state.courses = mergeDeep(
    //     state.courses,
    //     action.payload.entities.courses
    //   )
    // }
    //
    // if (entities.hasOwnProperty('products')) {
    //   state.products = mergeDeep(
    //     state.products,
    //     action.payload.entities.products
    //   )
    // }
    //
    // return state
    default:
      return state;
  }
};

export const selectActiveProductInfo = createSelector(
  [
    (state: AppState) => state.products.productInfos.products,
    (state: AppState) => state.products.activeProduct.id,
  ],
  (products, productId): IProductInfo => {
    return _get(products, productId, undefined);
  },
);

export function inRange(
  start1: number,
  end1: number,
  start2: number,
  end2: number,
) {
  return start1 <= end2 && start2 <= end1;
}

export const inRangeBy =
  (start1: number, end1: number) => (start2: number, end2: number) =>
    inRange(start1, end1, start2, end2);

function timeToInt(v: unknown): number {
  if (Number.isInteger(v)) {
    return v as number;
  }
  if (typeof v !== 'string') {
    return 0;
  }

  const intV = Number(v.replaceAll(':', ''));

  if (!Number.isInteger(intV)) {
    return 0;
  }

  return intV;
}

export const selectActiveProductMenuInfo = createSelector(
  [
    (state: AppState) => state.products.activeProduct.id,
    (state: AppState) => state.products.productInfos.menus,
    (state: AppState) => state.products.entities?.menus ?? {},
    (state: AppState) => state.filters.activeFilters.times,
    (state: AppState) => state.products.entities?.products ?? {},
  ],
  (
    productId,
    menus,
    menuStatus,
    _filterTimes,
    products,
  ): IProductInfoMenu[] => {
    const product = products[productId];

    const timeAvailableChecker = Object.values(product.times)
      .filter(i => i.status === 'in-stock')
      .map(i => {
        const timeInt = timeToInt(i.time);
        return inRangeBy(timeInt, timeInt);
      });

    return Object.values(menus)
      .filter(item => {
        if (item.restaurant_id !== productId) {
          return false;
        }
        // menu is already soldout
        if (item.status && item.status !== 'in-stock') {
          return false;
        }

        if (timeAvailableChecker.length === 0) {
          return true;
        }

        if (menuStatus[item.id]?.menu_status !== 'in-stock') {
          return false;
        }

        // const servingHour = servingHours(item.menu_type);

        // let menuEnd = timeToInt(servingHour?.time_end ?? '23:59:59');
        // let menuStart = timeToInt(servingHour?.time_start ?? '00:00:00');

        // if (!isNotExpiredNow(menuStart, menuEnd)) {
        //   return false;
        // }

        // if (
        //   menuEnd &&
        //   menuStart &&
        //   !timeAvailableChecker.some(fn => fn(menuStart, menuEnd))
        // ) {
        //   return false;
        // }

        let hasAvailableTime: boolean = true;
        // if (!menuEnd || !menuStart) {
        //   hasAvailableTime = inRange(startTime, endTime, menuStart, menuEnd);
        // }

        return item.restaurant_id === productId && hasAvailableTime;
      })
      .sort((a, b) => {
        const aSale = a.price_regular;
        const bSale = b.price_regular;
        return aSale === bSale ? 0 : aSale > bSale ? 1 : -1;
      })
      .sort((a, b) => {
        // a less than b
        if (a.menu_type === 'LUNCH' && b.menu_type !== 'LUNCH') {
          return 1;
        }

        // a greater than b
        if (a.menu_type !== 'LUNCH' && b.menu_type === 'LUNCH') {
          return -1;
        }

        return 0;
      });
    // const find: IProductInfo = _get(info, productId, undefined);
    // if (!find || find.menus.length === 0) {
    //   return [];
    // }
    //
    // return find.menus
    //   .map(menuId => {
    //     return _get(menus, menuId);
    //   })
    //   .filter(Boolean);
  },
);

export const selectProductMenuById = createSelector(
  [
    (_state: AppState, id: ProductId) => id,
    (state: AppState) => state.products.entities?.menus ?? {},
    (state: AppState) => state.filters.activeFilters.times,
    (state: AppState) => state.products.entities?.products ?? {},
  ],
  (productId, menus, filterTimes, products) => {
    const product = products[productId];

    const servingHours = (type: string) => {
      return product.serving_hours?.find(item => item.menu_type === type);
    };

    const timeAvailableChecker = Object.values(product.times)
      .filter(i => i.status === 'in-stock')
      .map(i => {
        const timeInt = timeToInt(i.time);
        return inRangeBy(timeInt, timeInt);
      });

    let [start, end] = filterTimes;
    let startTime = timeToInt(start);
    let endTime = timeToInt(end);

    const timeNowInt = timeToInt(format(new Date(), 'HH:mm:ss'));
    const isNotExpiredNow = (_start: number, end: number) => timeNowInt < end;

    return Object.values(menus)
      .filter(item => {
        // when product is already sold don't filter
        if (product.status !== 'in-stock') {
          return true;
        }

        if (item.status && item.status !== 'in-stock') {
          return false;
        }

        if (timeAvailableChecker.length === 0) {
          return true;
        }

        if (item?.menu_status !== 'in-stock') {
          return false;
        }

        const servingHour = servingHours(item.menu_type);

        let menuEnd = timeToInt(servingHour?.time_end ?? '23:59:59');
        let menuStart = timeToInt(servingHour?.time_start ?? '00:00:00');

        if (!isNotExpiredNow(menuStart, menuEnd)) {
          return false;
        }

        if (
          menuEnd &&
          menuStart &&
          !timeAvailableChecker.some(fn => fn(menuStart, menuEnd))
        ) {
          return false;
        }

        let hasAvailableTime: boolean = true;
        if (!menuEnd || !menuStart) {
          hasAvailableTime = inRange(startTime, endTime, menuStart, menuEnd);
        }

        if (product.menus.indexOf(item.id) > -1 && hasAvailableTime) {
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        const aSale = a.price_regular;
        const bSale = b.price_regular;
        return aSale === bSale ? 0 : aSale > bSale ? 1 : -1;
      })
      .sort((a, b) => {
        // a less than b
        if (a.menu_type === 'LUNCH' && b.menu_type !== 'LUNCH') {
          return 1;
        }

        // a greater than b
        if (a.menu_type !== 'LUNCH' && b.menu_type === 'LUNCH') {
          return -1;
        }

        return 0;
      });
  },
);

export const selectActiveProductMenu = createSelector(
  [
    (state: AppState) => state.products.activeProduct.id,
    (state: AppState) => state.products.entities?.menus ?? {},
    (state: AppState) => state.filters.activeFilters.times,
    (state: AppState) => state.products.entities?.products ?? {},
  ],
  (productId, menus, _filterTimes, products) => {
    const product = products[productId];

    const timeAvailableChecker = Object.values(product.times)
      .filter(i => i.status === 'in-stock')
      .map(i => {
        const timeInt = timeToInt(i.time);
        return inRangeBy(timeInt, timeInt);
      });

    return Object.values(menus)
      .filter(item => {
        if (product.status !== 'in-stock') {
          return true;
        }

        // menu is already soldout
        if (item.status && item.status !== 'in-stock') {
          return false;
        }

        if (timeAvailableChecker.length === 0) {
          return true;
        }

        if (item?.menu_status !== 'in-stock') {
          return false;
        }

        // const servingHour = servingHours(item.menu_type);
        //
        // let menuEnd = timeToInt(servingHour?.time_end ?? '23:59:59');
        // let menuStart = timeToInt(servingHour?.time_start ?? '00:00:00');
        //
        // if (!isNotExpiredNow(menuStart, menuEnd)) {
        //   return false;
        // }

        // if (
        //   menuEnd &&
        //   menuStart &&
        //   !timeAvailableChecker.some(fn => fn(menuStart, menuEnd))
        // ) {
        //   return false;
        // }

        let hasAvailableTime: boolean = true;
        // if (!menuEnd || !menuStart) {
        //   hasAvailableTime = inRange(startTime, endTime, menuStart, menuEnd);
        // }

        if (product.menus.indexOf(item.id) > -1 && hasAvailableTime) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        const aSale = a.price_regular;
        const bSale = b.price_regular;
        return aSale === bSale ? 0 : aSale > bSale ? 1 : -1;
      })
      .sort((a, b) => {
        // a less than b
        if (a.menu_type === 'LUNCH' && b.menu_type !== 'LUNCH') {
          return 1;
        }

        // a greater than b
        if (a.menu_type !== 'LUNCH' && b.menu_type === 'LUNCH') {
          return -1;
        }

        return 0;
      });
  },
);

export const selectActiveProductMenuCoursesInfo = createSelector(
  [
    (state: AppState) => state.products.productInfos.menus,
    (state: AppState) => state.products.productInfos.courses,
    (_: AppState, menuId: number) => menuId,
  ],
  (menus, courses, menuId) => {
    if (!menuId) {
      return;
    }
    const menu = _get<IProductInfoMenu>(menus ?? {}, menuId);
    if (!menu) {
      return false;
    }

    return menu.courses
      ?.map(courseId => {
        return _get<IProductMenuCourse>(courses ?? {}, courseId);
      })
      .filter(Boolean);
  },
);

export const makeSelectActiveProductMenuCoursesInfo = () =>
  selectActiveProductMenuCoursesInfo;
