/* eslint-disable @typescript-eslint/no-this-alias */
// copy from https://github.com/financial-times/polyfill-library
// Element.prototype.dataset
Object.defineProperty(Element.prototype, 'dataset', {
    get: function () {
        const element = this;
        const attributes = this.attributes;
        const map = {};

        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];

            if (
                attribute &&
                attribute.name &&
                /^data-\w[\w-]*$/.test(attribute.name)
            ) {
                const name = attribute.name;
                const value = attribute.value;

                const propName = name.substr(5).replace(/-./g, function (prop) {
                    return prop.charAt(1).toUpperCase();
                });

                Object.defineProperty(map, propName, {
                    enumerable: true,
                    get: function () {
                        return this.value;
                    }.bind({ value: value || '' }),
                    set: function setter(name, value) {
                        if (typeof value !== 'undefined') {
                            this.setAttribute(name, value);
                        } else {
                            this.removeAttribute(name);
                        }
                    }.bind(element, name),
                });
            }
        }

        return map;
    },
});
