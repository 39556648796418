import './observers';
import 'unfetch/polyfill';
import './ie';

export default () => {
    let polyfills = [];

    // dataset polyfill
    const isDataSetSupported = () => {
        if (!document.documentElement.dataset) {
            return false;
        }
        var el = document.createElement('div');
        el.setAttribute('data-a-b', 'c');
        return el.dataset && el.dataset.aB === 'c';
    };

    if (!isDataSetSupported()) {
        console.log('Element.dataset not supported. loading polyfill');
        require('./dataset.js');
    }

    // required by: yall.js
    if (!window.requestAnimationFrame) {
        require('raf/polyfill');
    }

    // string polyfill
    if (!('trim' in String.prototype) || !('trimEnd' in String.prototype)) {
        console.log(
            'String.prototype.trim | String.prototype.trimEnd is not supported. Loading polyfill',
        );
        require('core-js/features/string');
    }

    if (!('scrollBehavior' in document.documentElement.style)) {
        console.log(
            'document.documentElement.style.scrollBehavior not supported. Loading polyfill',
        );
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('smoothscroll-polyfill').polyfill();
    }

    return polyfills;
};
